@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    padding: 0;
    margin: 0;
    background-color: rgb(250, 249, 248);
    box-shadow: none;
  }
  h6,
  button,
  Link,
  a {
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }
  h1,
  h2 {
    font-family: "Roboto", sans-serif;
    color: rgb(33, 32, 32);
  }
  label {
    font-size: 1rem;
  }
 
  input {
    width: full;
    height: 3rem;
    color: black;
    background-color: transparent;
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
  }
  .card {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid rgb(216, 156, 16);
    height: 90%;
    width: 30%;
    color: white;
    padding: 2rem;
    padding-bottom: 2rem;
    position: absolute;
    margin: 0 auto;
  }
  @media (max-width: 1000px) {
    .card {
      width: 80%;
    }
    input {
      height: 1.5rem;
    }
  }
}
